import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

export default () => (
  <Layout>
    <SEO pageTitle="Booking and cancellation policy" />
    <Header pageTitle="Booking and cancellation policy" bgImage="backgrounds/header.jpg" />
    <section className="intro-text grey-bg">
      <div className="container">
        <p>Welcome to Birds of Beauty Parlour and thank you for choosing me to do your treatment.</p>
      </div>
    </section>
    <section className="body-text">
      <div className="container">
        <h2>Booking Process</h2>
        <p>Please check all contra-indications before booking an appointment as a non-refundable booking fee of 50% is required when making a booking.  This will be deducted from your final bill on the day of your treatment.  The best way to book an appointment with us is via our online booking system.</p>
        <p>A confirmation e-mail (check your junk) will be sent with the full address and any other information you will need to know.  If you are new to us, or booking a new treatment for you, we will then email you a few different consultation forms.  Please fill these in when you receive it/them, with as much information as possible, so we have time to read them before your appointment.  If you are re-booking a treatment you have had with us before, your previously completed forms will be emailed to you, to confirm that nothing has changed, since your previous visit.</p>
        <h2>Deposits</h2>
        <p>Refunds are not offered on any treatments provided.  We go through a thorough consultation process during your treatment, where I check that you are happy at each stage. So, I am confident you will be delighted with the outcome.</p>
        <h2>Consultation Process</h2>
        <p>If you are new to us, or having a new to you treatment by me, I will email you a consultation form(s). Please fill these in upon receipt.  If you are already a client of Birds of Beauty, your previously completed consultation forms will be sent to you, for you to confirm that there have been no changes in your medical history, since your previous visit.  If a face to face consultation is needed, this can be done over Zoom, if you struggle to get to the salon.</p>
        <h2>Right to Refuse Service</h2>
        <p>We reserve the right to refuse service under the following circumstances (including, but not limited to):</p>
        <ul>
          <li>If a client is deemed to be under the influence of alcohol or drugs.</li>
          <li>If the client threatens or acts rudely to the business owner, associates or customers.</li>
          <li>Is under the age of 18 years, or is unable to produce ID to prove age, and/or is not accompanied by an adult.</li>
          <li>Have any contra-indications or health conditions, making the treatment unsuitable.</li>
          <li>If there are multiple cancellations, or late arrivals.</li>
          <li>If I am made aware of any previous dishonest practices, such as attempting to obtain professional services without paying, or, has used social media in an abusive or damaging way.</li>
        </ul>
        <h2>Punctuality/late arrivals</h2>
        <p>Please arrive on time for your appointment.  Please note that if you are late for your appointment, we may have to shorten your treatment time by deducting the minutes that you are late by.  In some cases, we may not be able to carry out your treatment at all, and this will result in you losing this appointment, together, with your booking fee.  If you are late on more than 2 occasions, then on your next appointment, you will be required to pay for the treatment in full, prior to your attendance.  If lateness persists, after you have booked another treatment you will lose the full payment of the treatment you have purchased.</p>
        <h2>No Shows</h2>
        <p>Should you not show for your appointment, we reserve the right to charge you for the full appointment at your next service with us.</p>
        <h2>Cancellations</h2>
        <p>Any changes to the booking date and time must be notified a minimum of 24 hours prior, otherwise the booking fee will not be carried over to the new booking.</p>
        <h2>Age requirement for treatments</h2>
        <p>Certain treatments require the age consent to be over 18 years (this is for insurance purposes) 16-18years require an adult present during the treatment.  Only very few treatments can be performed on anyone under 16 years (again for insurance purposes).  A parent or guardian must also be present for anyone under the age of 16.</p>
        <h2>Refunds</h2>
        <p>Refunds are not offered on any treatments provided.  We go through a thorough consultation process during your treatment, where I check that you are happy at each stage.  So I am confident you will be delighted with the outcome.</p>
        <h2>Patch testing</h2>
        <p>Any treatment that requires a patch test will not take place until a patch test has been completed. (unless you are already contra-indicated). If you have had the same treatment within a different salon, you will still need a patch test carrying out at Birds of Beauty Parlour, for our records, and for insurance purposes.  Patch tests need to be carried out, in most cases, no less than 48 hours prior to your appointment, and no more than 7 days prior to your appointment.  For PMU services, a scratch test may be carried out less than 48 hours prior to your appointment.  If a reaction occurs, please inform us immediately.</p>
        <h2>Children on Premises</h2>
        <p>Children are only allowed to attend appointments if they are supervised by a responsible adult.</p>
        <h2>Mobile Phones</h2>
        <p>We politely ask you to put your mobile phone away during your treatment times.</p>
        <h2>Courses of Treatments</h2>
        <p>Please note all courses purchased by clients will have 1 month to use before it expires.</p>
        <h2>Gift Vouchers</h2>
        <p>You need to advise and present the gift certificate or discount voucher at the time of booking and Therapy Skincare has the right to reject any booking if the voucher is not presented. Vouchers cannot be redeemed for cash, sold or transferred. Please check expiry date on prepaid treatments or vouchers.</p>
        <h2>Complaints Procedure</h2>
        <p>As we go through a full consultation process both before and during your treatment.  I am confident that you will love your results.  However, if you are not happy with your treatment then please notify me in the following way. </p>
        <p>Please telephone me in the first instance to discuss your concerns. You can reach me on <a href="tel:07812187531">07812 187531</a>. If we are not able to resolve your concerns over the phone, then please put your concerns in writing to me and email to <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>.</p>
        <p>Please allow 3 working days for a response (excluding bank holidays and annual leave).</p>
        <h2>Personal Information</h2>
        <p>You agree to keep your personal records held by the salon updated with your full contact details – which will enable us to contact you in the event of any change relating to your appointments. Where possible, please provide both land line and mobile telephone numbers and email address.</p>
        <p>If you are unsure about anything or have any questions please call <a href="tel:07812187531">07812 187531</a>.</p>
        <p>Thank you for your understanding and we look forward to seeing you soon.</p>
      </div>
    </section>
  </Layout>
)